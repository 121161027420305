import React, { useMemo } from 'react';
import { PiArrowLeft, PiFolderFill, PiChalkboardSimple } from 'react-icons/pi';

import { isEmpty, isDemoAcc } from '../../Utils';

import { trackCreateProject } from '../../analytics';

import { URL, EVENT_TYPE_DEMO } from '../../constants';

import { useEventStore } from '../../stores/event';
import { useUserStore } from '../../stores/user';
import PremiumIcon from '../icons/premiumIcon';
import CollapseEvent from './collapseEvent';
import SideNavBtn from './sideNavBtn';

const SideNav = () => {
  const user = useUserStore((state) => state.user);
  const userEvents = useEventStore((state) => state.userEvents);
  const currentEvent = useEventStore((state) => state.currentEvent);

  const { recentEvents, completedEvents } = useMemo(() => {
    const recentEvents = [];
    const completedEvents = [];

    userEvents.forEach((event) => {
      if (event.type !== EVENT_TYPE_DEMO) {
        if (event.stage === 'generation') completedEvents.push(event);
        else recentEvents.push(event);
      }
    });

    return { recentEvents, completedEvents };
  }, [userEvents]);

  const isFreemium = user?.userType === 'freemium';

  const pathname = window.location.pathname;

  // show current event block if the page is linked to the event
  const showCurrentEvent = useMemo(() => {
    return currentEvent && window.location.href.includes(currentEvent.eventId);
  }, [currentEvent, window.location.href]);

  return (
    <aside
      id="main-side-nav"
      className="fixed top-[var(--header-height)] left-0 w-[calc(var(--size-nav-width)_+_6.5vw)] h-[calc(100vh_-_var(--header-height))] z-40 transition-transform"
      aria-label="SideNav"
    >
      <div className="h-full flex flex-col pl-[3.5vw] pr-[3vw] py-[var(--content-vertical-spacing)] bg-true-white sm:bg-transparent overflow-y-auto">
        <div className="flex flex-col pb-4 gap-2">
          {pathname !== URL.HOME && (
            <SideNavBtn
              icon={<PiArrowLeft />}
              text="Back to Home"
              href={URL.HOME}
              extraIcon={<div></div>}
            />
          )}

          {/* only shown on small screens */}
          <SideNavBtn
            icon={
              <PiFolderFill className="fill-[url('#icon-primary-gradient')]" />
            }
            text="Create Project"
            href={URL.DASH_EVENT_BUILDER}
            onClick={() => trackCreateProject('Side Nav')}
            extraIcon={
              userEvents.length > 0 && <PremiumIcon withCircle={true} />
            }
            isProminent
            addClass="flex sm:hidden"
          />

          {showCurrentEvent && (
            <CollapseEvent event={currentEvent} isCurrent={true} />
          )}
        </div>

        {/* if event is not selected – render easy links block */}

        {!showCurrentEvent && (
          <div className="flex flex-col gap-2 overflow-auto grow">
            {!isEmpty(recentEvents) && (
              <div className="flex flex-col gap-2">
                <div className="font-bold">Recent projects</div>

                <div>
                  {recentEvents.map((event, index) => (
                    <CollapseEvent event={event} key={index} />
                  ))}
                </div>
              </div>
            )}

            {!isEmpty(completedEvents) && (
              <div className="flex flex-col gap-2">
                <div className="font-bold">Completed projects</div>

                <div>
                  {completedEvents.map((event, index) => {
                    return <CollapseEvent event={event} key={index} />;
                  })}
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col mt-auto pt-4 gap-2 bottom-block">
          {/* for demo acc only – show link to brand page */}
          {isDemoAcc(user) && (
            <SideNavBtn
              icon={<PiChalkboardSimple />}
              text="Brand"
              href={URL.DASH_BRAND}
              extraIcon={isFreemium && <PremiumIcon withCircle={true} />}
            />
          )}
        </div>
      </div>
    </aside>
  );
};

export default SideNav;
