import React from 'react';

import { formatDate } from '../../../Utils';
import { useEventStore } from '../../../stores/event';

const WaitStep = (props) => {
  const { isAssetsUploaded, isReleaseDatePassed } = props;

  const currentEvent = useEventStore((state) => state.currentEvent);

  return (
    <React.Fragment>
      {currentEvent?.attendeeReviewData?.isRemindLater && !isAssetsUploaded && (
        <div className="font-bold">
          Remember to upload your clips and highlight reel by{' '}
          {formatDate(
            currentEvent.attendeeReviewData?.releaseDate?.slice(0, 10),
          )}
          .
        </div>
      )}

      <div>
        On release day, your assets will be automatically analysed, and all
        attendees will receive an email with a link to their review page.
      </div>

      <div>
        Please check back later — once the first video is generated, you’ll gain
        access to our ‘Share’ page, where you can view the generated videos and
        event statistics.
      </div>

      {!isReleaseDatePassed && (
        <div>
          You can update the release date up to a day before, but be sure to
          inform your attendees about the deadline change.
        </div>
      )}
    </React.Fragment>
  );
};

export default WaitStep;
