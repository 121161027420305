import { getFileImg } from '../../Utils';
import config from '../../config';
import {
  getAccessTokenClient,
  getApiKeyClient,
  getAttendeeClient,
} from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl, media: mediaUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);
const mediaAccessTokenClient = () => getAccessTokenClient(mediaUrl);
const apiKeyClient = () => getApiKeyClient(baseUrl);
const mediaAttendeeClient = () => getAttendeeClient(mediaUrl);

export const generateCreations = async (data, isPublic) => {
  try {
    await (isPublic ? mediaAttendeeClient : mediaAccessTokenClient)().post(
      '/api/generate-creations',
      { ...data },
    );
  } catch (err) {
    if (err.response.data.message?.includes('Invalid Video Template'))
      throw new Error('Your template has some missing wildcards');
    else throw new Error('Error generating creations. Try again later');
  }
};

export const getCreationResults = async (eventId, isPublic) => {
  try {
    const response = await (isPublic ? apiKeyClient : accessTokenClient)().get(
      `api/creation-results?eventId=${eventId}`,
    );

    let generatedFaces = [];

    response.data.results.forEach((faceObj) => {
      faceObj.face.img = getFileImg(faceObj.images[0]);
      if (faceObj.creations.length > 0) generatedFaces.push(faceObj);
    });

    return generatedFaces;
  } catch (err) {
    throw new Error(`Error getting creation results: ${err.message}`);
  }
};

// public API used in share video page
export const getOneCreation = async (parentElementId) => {
  try {
    const response = await apiKeyClient().get(
      `api/files/by-parent-element/${parentElementId}?fileKind=video-creation`,
    );
    return response.data.files[0];
  } catch (err) {
    throw new Error(`Error getting a creation: ${err.message}`);
  }
};

export const getCreationWithData = async (eventId, faceId) => {
  const creations = await getCreationResults(eventId, true);

  const foundCreation = creations.find(
    (creation) => creation.face.elementId === faceId,
  );

  return foundCreation;
};
