import React from 'react';

import { isEmpty } from '../../../Utils';
import {
  trackCheckInDetailsModalClickYes,
  trackCheckInDetailsModalClickNo,
} from '../../../analytics';
import { Button } from '../../../components';
import { getWildcardTextMaximum } from '../../../components/wildcardText';

const tipsList = [
  'We’ve detected a face and analysing over a million data points. Hey there, good lookin',
  'Your file is currently taking a scenic route through cyberspace. Somewhere in a parallel universe, you are a star!',
  'We’re now soaring through the digital realm like paper airplanes and hopefully won’t get stuck on virtual trees',
  'Uploading a file is like pilates for processors',
  'Encrypting your data before sending it to space',
  'Our digital squirrels are working hard to store your files in the acorn-shaped cloud',
];

export const getDetailsText = () => {
  const selfieInfoPoints = [
    'No hats, sunglasses or masks',
    'Should only include one person in the photo',
    'Should be taken as close as possible to include head and shoulder only',
    'Face should be clearly shown and not blurry',
    'Use high resolution image with good lighting',
  ];

  return { selfieInfoPoints, tipsList };
};

export const getVideoText = (
  event,
  face,
  isUploading,
  isDetailsProvided,
  onHideConfirmModal,
  requiredShots,
) => {
  const ugcInfoPoints = [
    'Upload at least ' +
      getWildcardTextMaximum(
        requiredShots ?? event.wildcards,
        'video',
        'around',
      ),
    `Make sure to record videos in <b>${event.videoMode} orientation</b>`,
  ];

  if (isUploading)
    return {
      ugcInfoPoints,
      modalData: {
        heading: "We're uploading your files",
        subheading: null,
        btn: null,
      },
      tipsList,
    };

  if (isDetailsProvided)
    return {
      ugcInfoPoints,
      modalData: {
        heading: `Thank you for providing your details, ${face.name}!`,
        subheading:
          'You may come back to this page to upload more videos later.',
        btn: (
          <Button className="w-full" onClick={() => onHideConfirmModal(false)}>
            OK
          </Button>
        ),
      },
      tipsList: null,
    };

  // if missing clips were uploaded
  if (!isEmpty(requiredShots))
    return {
      ugcInfoPoints,
      modalData: {
        heading: `Great! You have successfully uploaded your videos to ${event.name}.`,
        btn: (
          <Button className="w-full" onClick={() => onHideConfirmModal(false)}>
            OK
          </Button>
        ),
      },
      tipsList: null,
    };

  //  some of the details are still missing
  const isDetailsMissing =
    isEmpty(face.photos) ||
    isEmpty(face.instagram) ||
    (!isEmpty(event.customFieldLabel) && isEmpty(face.customFieldData));

  return {
    ugcInfoPoints,
    modalData: {
      heading: `Great! You have successfully checked in to ${event.name}.`,
      subheading: (
        <>
          <div>You may come back to this page to upload more videos later.</div>
          {isDetailsMissing && (
            <div>
              Meanwhile, would you like to tell us a bit about yourself so we
              can personalise your experience even more?
            </div>
          )}
        </>
      ),
      btn: isDetailsMissing ? (
        <div className="w-full flex items-center justify-between gap-4">
          <Button
            className="w-1/2"
            onClick={() => {
              onHideConfirmModal(true);
              trackCheckInDetailsModalClickYes();
            }}
          >
            Yes
          </Button>
          <Button
            variant="pink"
            className="w-1/2"
            onClick={() => {
              onHideConfirmModal(false);
              trackCheckInDetailsModalClickNo();
            }}
          >
            No
          </Button>
        </div>
      ) : (
        <Button className="w-full" onClick={() => onHideConfirmModal(false)}>
          OK
        </Button>
      ),
    },
    tipsList: null,
  };
};
