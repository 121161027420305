import config from '../../config';
import { getAccessTokenClient, getAttendeeClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);
const attendeeClient = () => getAttendeeClient(baseUrl);

export const privateFileCreate = async (files) => {
  try {
    await accessTokenClient().put('api/files', JSON.stringify({ files }), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('File saved');
  } catch (err) {
    throw new Error(`Error saving file: ${err.message}`);
  }
};

export const attendeeFileCreate = async (files) => {
  try {
    await attendeeClient().put('api/files', JSON.stringify({ files }), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('File saved');
  } catch (err) {
    throw new Error(`Error saving file: ${err.message}`);
  }
};

export const fileDelete = async (elementId) => {
  try {
    await accessTokenClient().delete(`api/files/${elementId}`);
    console.log('File deleted');
  } catch (err) {
    throw new Error(`Error deleting file: ${err.message}`);
  }
};

export const getAttendeeFaceVideos = async (eventId, parentElementId) => {
  try {
    const response = await attendeeClient().get(
      `api/event/${eventId}/face/${parentElementId}/files?fileKind=video-footage`,
    );
    return response.data.files;
  } catch (err) {
    throw new Error(`Error getting face videos: ${err.message}`);
  }
};

export const getAttendeeFacePhotos = async (eventId, parentElementId) => {
  try {
    const response = await attendeeClient().get(
      `api/event/${eventId}/face/${parentElementId}/files?fileType=image`,
    );
    return response.data.files;
  } catch (err) {
    throw new Error(`Error getting face images: ${err.message}`);
  }
};

export const getSelectFiles = async (eventId) => {
  try {
    const response = await accessTokenClient().get(
      `api/files?eventId=${eventId}&fileType=video&fileKind=VideoFootage`,
    );
    return response.data.files;
  } catch (err) {
    throw new Error(`Error getting selects files: ${err.message}`);
  }
};

export const getClipResults = async (eventId) => {
  try {
    const response = await accessTokenClient().get(
      `api/clip-results?eventId=${eventId}`,
    );
    return response.data;
  } catch (err) {
    throw new Error(`Error getting clip results: ${err.message}`);
  }
};

export const getAttendeeClips = async (faceId) => {
  try {
    const response = await attendeeClient().get(`api/clips?faceId=${faceId}`);

    return response.data.clips.map(({ persons, ...rest }) => rest);
  } catch (err) {
    throw new Error(`Error getting person's clips: ${err.message}`);
  }
};
