import React, { useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getPluralPhrase } from '../../../Utils';

import { trackVBClickUploadMore } from '../../../analytics';

import { Button, Spinner, Alert } from '../../../components';
import { URL } from '../../../constants';

import { setAttendeeReviewData } from '../../../services/api/mongodb';
import { useEventStore } from '../../../stores/event';

import TimelineBtn from '../components/timelineBtn';

const SelectStep = (props) => {
  const {
    peopleCount,
    clipsCount,
    isPeopleUploaded,
    isHlReelUploaded,
    isClipsUploaded,
    isDemo,
    isReleaseDatePassed,
  } = props;

  const eventId = useParams()?.eventId;

  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

  const [isSaving, setIsSaving] = useState(false);

  const { isAttendeeReview, isRemindLater } = useMemo(() => {
    return {
      isAttendeeReview: currentEvent?.attendeeReviewData?.isAttendeeReview,
      isRemindLater: currentEvent?.attendeeReviewData?.isRemindLater,
    };
  }, [currentEvent?.attendeeReviewData]);

  const isAssetsUploaded = useMemo(() => {
    if (isAttendeeReview) return isHlReelUploaded && isClipsUploaded;
    return isPeopleUploaded && isHlReelUploaded && isClipsUploaded;
  }, [isAttendeeReview, isPeopleUploaded, isClipsUploaded, isHlReelUploaded]);

  const isAllowUploadMore = useMemo(() => {
    return (
      (!isDemo && !isAttendeeReview) ||
      (isDemo && !demoData.isLimited) ||
      (isAttendeeReview && !isReleaseDatePassed) ||
      (isAttendeeReview && isRemindLater && currentEvent.stage === 'upload')
    );
  }, [
    currentEvent.stage,
    demoData.isLimited,
    isAttendeeReview,
    isDemo,
    isReleaseDatePassed,
    isRemindLater,
  ]);

  if (isSaving) return <Spinner />;

  if (isAssetsUploaded || isRemindLater)
    return (
      <React.Fragment>
        {!isAssetsUploaded && (
          <Alert
            variant="red"
            alertData={{
              variant: 'warning',
              text: 'Some assets are still missing',
            }}
          />
        )}

        {!isAttendeeReview && (
          <div>
            <b>{getPluralPhrase(peopleCount, 'person')}</b> checked-in.{' '}
            {isAllowUploadMore && (
              <Link
                to={`/${eventId}${URL.VB_PEOPLE}`}
                onClick={() => trackVBClickUploadMore('people', currentEvent)}
              >
                Check-in more people
              </Link>
            )}
          </div>
        )}

        <div>
          <b>{isHlReelUploaded ? 'Highlight reel' : 'No highlight reel'}</b>{' '}
          template uploaded.{' '}
          {isAllowUploadMore && (
            <Link
              to={`/${eventId}${URL.VB_HL_REEL}`}
              onClick={() => trackVBClickUploadMore('hl_reel', currentEvent)}
            >
              {isHlReelUploaded ? 'Re-upload' : 'Upload'}
            </Link>
          )}
        </div>

        <div>
          <b>{getPluralPhrase(clipsCount, 'clip')}</b> uploaded.{' '}
          {isAllowUploadMore && (
            <Link
              to={`/${eventId}${URL.VB_CLIPS}`}
              onClick={() => trackVBClickUploadMore('clips', currentEvent)}
            >
              Upload {isClipsUploaded && 'more'} clips
            </Link>
          )}
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      {!isAttendeeReview ? (
        <div>
          Upload your highlight reel template, some clips and tell us the people
          to identify in your clips.
        </div>
      ) : (
        <div>Upload your highlight reel template and some clips.</div>
      )}

      {!isAttendeeReview && (
        <TimelineBtn
          type="people"
          heading="People"
          subheading={
            !isPeopleUploaded
              ? 'Show our AI who to make highlight reels for'
              : `<b>${getPluralPhrase(peopleCount, 'person')}</b> checked-in`
          }
          isComplete={isPeopleUploaded}
          link={`/${eventId}${URL.VB_PEOPLE}`}
        />
      )}

      <TimelineBtn
        type="hl-reel"
        heading="Highlight Reel"
        subheading={
          !isHlReelUploaded
            ? 'Upload a highlight reel template'
            : '<b>Highlight reel</b> template uploaded'
        }
        isComplete={isHlReelUploaded}
        link={`/${eventId}${URL.VB_HL_REEL}`}
      />

      <TimelineBtn
        type="clips"
        heading="Clips"
        subheading={
          !isClipsUploaded
            ? 'Upload clips for our AI to find the best moments for each person'
            : `<b>${getPluralPhrase(clipsCount, 'clip')}</b> uploaded`
        }
        isComplete={isClipsUploaded}
        link={`/${eventId}${URL.VB_CLIPS}`}
      />

      {isAttendeeReview && (
        <div>
          Don’t have your assets ready yet? No worries!{' '}
          <Button
            variant="link"
            onClick={async () => {
              setIsSaving(true);

              await setAttendeeReviewData({
                eventId: eventId,
                isRemindLater: 'true',
              })
                .then(async () => await updateCurrentEvent())
                .catch(() =>
                  toast.error('Something went wrong. Try again later'),
                )
                .finally(() => setIsSaving(false));
            }}
          >
            Skip this step
          </Button>
          , and we’ll send you a reminder 2 days before your release date.
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectStep;
