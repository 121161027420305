import React from 'react';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';

const LabelIcon = (props) => {
  const { error, completed, active } = props;

  if (error) return <div className="w-6 h-6 bg-error-900 rounded-full" />;
  if (completed) return <div className="w-6 h-6 bg-success-900 rounded-full" />;
  if (active) return <div className="w-6 h-6 bg-black rounded-full" />;
  return <div className="w-6 h-6 bg-grey-900 rounded-full" />;
};

const Timeline = (props) => {
  const { steps, activeStep } = props;

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      className="w-full max-w-full sm:w-1/2 sm:max-w-1/2"
    >
      {steps.map((step, index) => {
        return (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={LabelIcon}
              error={step.isError}
              sx={{
                '.MuiStepLabel-iconContainer': { paddingRight: '20px' },
                '.MuiStepIcon-text': { display: 'none' },
                '& .Mui-completed > *': { color: 'var(--success-color)' },
                '& .Mui-active > *': { color: '#000 !important' },
                '& .Mui-disabled > *': { color: 'var(--grey)' },
                '& .Mui-error > *': { color: 'var(--error-color)' },
              }}
            >
              <div className="text-xl font-bold uppercase">{step.label}</div>
            </StepLabel>

            {index <= activeStep && (
              <StepContent
                sx={{
                  paddingY: !!step.content ? '0.5rem' : '0',
                  paddingLeft: '2rem',
                  paddingRight: '0',
                }}
                TransitionProps={{ in: true }}
              >
                <div className="flex flex-col items-start gap-4">
                  {step.content}
                </div>
              </StepContent>
            )}
          </Step>
        );
      })}
    </Stepper>
  );
};

export default Timeline;
