import React, { useState, useEffect, useMemo } from 'react';
import {
  PiShareFat,
  PiDownloadSimple,
  PiArrowsOut,
  PiArrowsIn,
} from 'react-icons/pi';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  goTo,
  isEmpty,
  isPlural,
  getPluralPhrase,
  round,
  getPct,
  getShareLink,
} from '../../../Utils';
import { sendBulkFaceEmail } from '../../../UtilsEmail';

import {
  trackFirstProjectComplete,
  trackProjectComplete,
  trackClickPayToRemoveWatermark,
  trackShareOpenShareHubModal,
  trackShareDownload,
  trackShareToggleVideoSize,
  trackShareBulkShare,
  trackShareDemoShare,
  trackShareReOpenWorkflow,
  trackDemoStartAgain,
} from '../../../analytics';

import { Spinner, Button, Alert } from '../../../components';
import ConfirmModal from '../../../components/modals/confirmModal';
import ErrorModal from '../../../components/modals/errorModal';
import PageHeader from '../../../components/pageHeader';
import PageSpinner from '../../../components/pageSpinner';
import PopoverTrigger from '../../../components/popoverTrigger';
import { zipAndDownloadVideos } from '../../../components/videoDownload';

import config from '../../../config';
import { S3_BUCKET_URL } from '../../../constants';

import {
  URL,
  DEMO_ID,
  EMAILS,
  FACE_EMAIL_STATUS,
  FIRST_EVENT_PROMO_CODE,
} from '../../../constants';

import { getCreationResults } from '../../../services/api/creations';
import { sendEmail } from '../../../services/api/emails';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';

import BackBtn from '../components/backBtn';
import { demoFifa } from '../components/demoData';
import DoneBtn from '../components/doneBtn';
import DemoUpsellModal from './components/demoUpsellModal';
import ShareFaceBlock from './components/shareFaceBlock';
import ShareHubModal from './components/shareHubModal';
import StatsBlock from './components/statsBlock';

const { featureToggles, stripeLinks } = config;

const Share = () => {
  const eventId = useParams()?.eventId;

  const queryParameters = new URLSearchParams(window.location.search);
  const payment_success = queryParameters.get('payment_success') === 'true';

  const user = useUserStore((state) => state.user);
  const setEventId = useEventStore((state) => state.setEventId);
  const setDemoEvent = useEventStore((state) => state.setDemoEvent);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const userEvents = useEventStore((state) => state.userEvents);

  const [isLoading, setIsLoading] = useState(true);
  const [isIncreased, setIsIncreased] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);

  const [people, setPeople] = useState([]);
  const [peopleEmails, setPeopleEmails] = useState({});
  const [stats, setStats] = useState(0);
  const [isEmailsSending, setIsEmailsSending] = useState(false);
  const [showShareHubModal, setShowShareHubModal] = useState(false);
  const [showDemoUpsellModal, setShowDemoUpsellModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const hubId = isDemo ? demoData.event.eventId : eventId;
  const hubLink = getShareLink(hubId);

  // set event id on mount
  useEffect(() => {
    if (isDemo) setDemoEvent(eventId);
    else setEventId(eventId);
  }, [eventId, isDemo, setDemoEvent, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      await fetchGenerationData();

      if (payment_success) {
        setShowPaymentModal(true);
        window.history.pushState({}, '', `/${eventId}${URL.VB_SHARE}`);
      }

      handleAnalytics();

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  // when user opens share page for the first time – add GA events
  const handleAnalytics = () => {
    const projectsUpd =
      JSON.parse(localStorage.getItem('completedProjects')) ?? [];
    const isFirstCompletedProject =
      userEvents.filter((event) => event.stage === 'generation')?.length ===
        1 && localStorage.getItem('hasFirstCompletedProject') !== 'true';

    // add project completion analytics
    // check if eventId is not in localStorage completedProjects – to avoid duplicate events
    if (!projectsUpd.includes(eventId)) {
      trackProjectComplete(currentEvent);

      projectsUpd.push(eventId);
      localStorage.setItem('completedProjects', JSON.stringify(projectsUpd));
    }

    // if it's first completed project – add corresponding analytics
    if (isFirstCompletedProject) {
      trackFirstProjectComplete(currentEvent);

      localStorage.setItem('hasFirstCompletedProject', 'true');
    }
  };

  useEffect(() => {
    let emailedNum = 0;
    let emailedTotal = 0;

    let viewedNum = 0;
    let viewedTotal = 0;

    let downloadedNum = 0;
    let downloadedTotal = 0;

    let sharedNum = 0;
    let sharedTotal = 0;

    people.forEach((person) => {
      const { face, metrics } = person;
      const id = face.elementId;

      if (metrics.emailedCount >= 1) emailedNum++;
      emailedTotal += metrics.emailedCount ?? 0;

      if (metrics.viewedCount >= 1) viewedNum++;
      viewedTotal += metrics.viewedCount ?? 0;

      if (metrics.downloadedCount >= 1) downloadedNum++;
      downloadedTotal += metrics.downloadedCount ?? 0;

      if (metrics.sharedCount >= 1) sharedNum++;
      sharedTotal += metrics.sharedCount ?? 0;

      if (!peopleEmails[id])
        peopleEmails[id] = {
          email: face.email || '',
          videoLink: getShareLink(hubId, id),
          metricId: metrics.metricId,
          status: !!metrics.emailedCount
            ? FACE_EMAIL_STATUS.RESEND
            : FACE_EMAIL_STATUS.NOT_SENT,
          err: null,
        };
    });

    const emailedPct = getPct(emailedNum / people.length);
    const emailedAverage = round(emailedTotal / people.length);

    const viewedPct = getPct(viewedNum / people.length);
    const viewedAverage = round(viewedTotal / people.length);

    const downloadedPct = getPct(downloadedNum / people.length);
    const downloadedAverage = round(downloadedTotal / people.length);

    const sharedPct = getPct(sharedNum / people.length);
    const sharedAverage = round(sharedTotal / people.length);

    setPeopleEmails({ ...peopleEmails });
    setStats({
      emailedPct,
      emailedTotal,
      emailedAverage,
      viewedPct,
      viewedTotal,
      viewedAverage,
      downloadedPct,
      downloadedTotal,
      downloadedAverage,
      sharedPct,
      sharedTotal,
      sharedAverage,
    });
  }, [people]);

  const fetchGenerationData = async () => {
    try {
      let generatedPeople = [];

      // demo event
      if (isDemo) {
        demoData.people.forEach((person) => {
          if (person.isSelected && person.isGenerated) {
            const foundCreation = demoData.creations[
              person.face.elementId
            ].find((creation) => creation.isSelected);

            // imitate data returned by getCreationResults
            generatedPeople.push({
              face: { ...person.face, img: person.files[0].url },
              creations: [foundCreation],
              metrics: {},
              finalVideoUri: foundCreation.uri,
            });
          }
        });
      }

      // usual event
      else generatedPeople = await getCreationResults(eventId);

      setPeople(generatedPeople);
    } catch (err) {
      console.error(`Error fetching the generated videos: ${err}`);
      setError({ subheading: 'Error fetching the generated videos' });
    }
  };

  const sortedPeople = useMemo(() => {
    return people.sort((one, other) => {
      if (!!one.face.email && !other.face.email) return -1;
      else return 1;
    });
  }, [people]);

  const canBulkShare = useMemo(() => {
    return !isEmpty(
      Object.keys(peopleEmails)
        .map((id) => peopleEmails[id])
        .filter((person) => !!person.email),
    );
  }, [peopleEmails]);

  const resetDemo = () => {
    localStorage.setItem('demoData', JSON.stringify(demoFifa));

    trackDemoStartAgain('Share');
  };

  const afterEmailSend = () => {
    fetchGenerationData();
  };

  const handleMainShare = async (e) => {
    // demo events
    if (isDemo) {
      setIsEmailsSending(true);

      const person = people[0];

      const emailData = {
        recipientEmail: user.email,
        templateId: EMAILS.INDIVIDUAL_CREATION,
        dynamicTemplateData: {
          event_name: currentEvent.name,
          name: person.face.name,
          share_url: peopleEmails[person.face.elementId].videoLink,
        },
      };

      const isSuccess = await sendEmail(emailData);

      if (isSuccess) {
        setShowDemoUpsellModal(true);
        trackShareDemoShare();
      } else
        toast.error(
          <div>
            Email couldn't be sent to <b>{user.email}</b>, please try again
            later.
          </div>,
        );

      setIsEmailsSending(false);
    }

    // usual events
    else {
      const peopleUpd = people.map((person) => person.face);

      const { isSuccess } = await sendBulkFaceEmail(
        e,
        'individual-creation',
        currentEvent,
        peopleUpd,
        peopleEmails,
        setPeopleEmails,
        setIsEmailsSending,
      );

      if (isSuccess) {
        afterEmailSend();
        trackShareBulkShare();
      }
    }
  };

  const handleDownloadAll = async () => {
    setIsDownloading(true);
    let videoUrls = [];

    people.forEach((person) => {
      videoUrls.push({
        url: `${S3_BUCKET_URL}.files${!featureToggles.paywall || currentEvent.paid ? person.finalVideoUri : person.previewVideoUri}`,
        faceName: person.face.name,
      });
    });

    await zipAndDownloadVideos(currentEvent.name, videoUrls);

    trackShareDownload('hub', currentEvent);

    setIsDownloading(false);
  };

  const btnClass =
    'group flex items-center justify-center p-2 font-bold text-[1.25rem] rounded-md hover:bg-black hover:text-white disabled:opacity-65';

  return (
    <PageSpinner
      isLoading={isLoading}
      title={`Share – ${currentEvent?.name}`}
      isPageContainer
    >
      <BackBtn />

      <div className="flex flex-col gap-6">
        <PageHeader
          heading={`Share ${currentEvent && ' – ' + currentEvent.name}`}
          subheading={
            <>
              Pesonalised Highlight Reel{isPlural(people)}{' '}
              {isPlural(people) ? 'are' : 'is'} ready to share with{' '}
              {getPluralPhrase(people, 'person')}!{' '}
              {isDemo ? (
                <span>
                  Want to try the sample project again?{' '}
                  <Link
                    to={`/${eventId}${URL.VIDEO_BUILDER}`}
                    onClick={resetDemo}
                  >
                    Re-start the workflow
                  </Link>
                </span>
              ) : (
                <span>
                  Want to generate reels for more people?{' '}
                  <Link
                    to={`/${eventId}${URL.VIDEO_BUILDER}`}
                    onClick={trackShareReOpenWorkflow}
                  >
                    Re-open the workflow
                  </Link>
                </span>
              )}
            </>
          }
          icons={
            !isEmpty(people) && (
              <div className="flex">
                <PopoverTrigger
                  content={
                    <button
                      className={btnClass}
                      onClick={() => {
                        setShowShareHubModal(true);
                        trackShareOpenShareHubModal(currentEvent);
                      }}
                    >
                      <PiShareFat />
                    </button>
                  }
                  placement="bottom"
                  popoverHeader="Share Hub Page"
                  popoverBody="Hub Page has all personalised highlight reels in a single view"
                />

                <PopoverTrigger
                  content={
                    <button
                      className={btnClass}
                      onClick={handleDownloadAll}
                      disabled={isDownloading}
                    >
                      {isDownloading ? (
                        <Spinner size="sm" hoverColor="white" />
                      ) : (
                        <PiDownloadSimple />
                      )}
                    </button>
                  }
                  placement="bottom"
                  popoverHeader="Download all"
                  popoverBody="All generated videos in one ZIP file"
                />

                {window.innerWidth >= 640 && (
                  <PopoverTrigger
                    content={
                      <button
                        className={btnClass}
                        onClick={() => {
                          setIsIncreased(!isIncreased);
                          trackShareToggleVideoSize(currentEvent, !isIncreased);
                        }}
                      >
                        {isIncreased ? <PiArrowsIn /> : <PiArrowsOut />}
                      </button>
                    }
                    placement="bottom"
                    popoverHeader={isIncreased ? 'Reduce' : 'Increase'}
                    popoverBody={
                      isIncreased
                        ? 'Reduce the video size for an easy overview of all videos at a glance'
                        : 'Increase the video size for an easy review of each generated video'
                    }
                  />
                )}
              </div>
            )
          }
        />

        {isEmpty(people) ? (
          <React.Fragment>
            <Alert
              alertData={{
                variant: 'warning',
                text: (
                  <>
                    Hmm, seems like you don't have any videos generated yet.
                    Come back when you complete all{' '}
                    <Link to={`/${eventId}${URL.VIDEO_BUILDER}`}>
                      Video Builder
                    </Link>{' '}
                    steps.
                  </>
                ),
              }}
              addClass="w-full sm:w-3/4"
            />

            <DoneBtn isBack />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {featureToggles.paywall && !currentEvent?.paid && (
              <div className="animated-box-primary flex flex-col sm:flex-row items-center p-[2px] rounded-md animate-running-2">
                <div className="w-full flex flex-row items-center justify-between p-3 sm:p-4 gap-2 sm:gap-4 text-left text-black bg-white rounded-t sm:rounded-l sm:rounded-tr-none">
                  <div>
                    Remove watermarks now for only{' '}
                    <span className="line-through">$249</span>{' '}
                    <span className="font-bold text-[1.1rem] bg-gradient-primary-right bg-clip-text text-transparent">
                      $39
                    </span>
                  </div>
                </div>

                <button
                  className="w-full sm:w-32 p-3 sm:p-4 text-white whitespace-nowrap transition-all duration-200 sm:hover:w-72"
                  onClick={() => {
                    goTo(
                      `${stripeLinks.removeWatermarks}?client_reference_id=${eventId}&prefilled_email=${user.email}&prefilled_promo_code=${FIRST_EVENT_PROMO_CODE}`,
                    );
                    trackClickPayToRemoveWatermark();
                  }}
                >
                  Pay now
                </button>
              </div>
            )}

            {!isDemo && <StatsBlock stats={stats} />}

            <div className="w-full flex flex-col items-center gap-2">
              <PopoverTrigger
                content={
                  <button
                    className="w-full sm:w-52 h-[var(--form-el-height)] flex items-center justify-center px-3 py-1.5 bg-black text-lg text-white rounded-full hover:opacity-80 disabled:opacity-65"
                    onClick={handleMainShare}
                    disabled={!canBulkShare && !isDemo}
                  >
                    <div
                      className={`absolute ${!isEmailsSending ? 'opacity-100' : 'opacity-0'}`}
                    >
                      {isDemo ? 'Share' : 'Share to All'}
                    </div>

                    <Spinner
                      size="lg"
                      color="white"
                      className={`absolute  ${isEmailsSending ? 'opacity-100' : 'opacity-0'}`}
                    />
                  </button>
                }
                placement="top"
                popoverHeader={isDemo ? 'Share to yourself' : 'Share to all'}
                popoverBody={
                  isDemo
                    ? 'Share a personalised highlight reel with yourself to preview the email your attendees will receive'
                    : canBulkShare
                      ? 'Share personalised highlight reels to all with email below'
                      : 'Unable to share, as no attendee emails are provided. Fill in the emails below, and share either individually or in bulk'
                }
              />
            </div>

            <div className="w-full flex flex-wrap gap-8">
              {sortedPeople.map((person) => (
                <ShareFaceBlock
                  event={currentEvent}
                  person={person}
                  peopleEmails={peopleEmails}
                  setPeopleEmails={setPeopleEmails}
                  afterEmailSend={afterEmailSend}
                  isDemo={isDemo}
                  isIncreased={isIncreased}
                />
              ))}
            </div>
          </React.Fragment>
        )}
      </div>

      {/* share hub modal */}
      <ShareHubModal
        show={showShareHubModal}
        onHide={() => setShowShareHubModal(false)}
        hubLink={hubLink}
        isDemo={isDemo}
      />

      {/* demo complete modal */}
      <DemoUpsellModal show={showDemoUpsellModal} />

      {/* payment modal */}
      <ConfirmModal
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        heading="Payment successful"
        subheading="Thanks for upgrading your project. We've now generated watermark-free highlight reels for everyone to share and enjoy."
        btn={
          <Button className="w-full" onClick={() => setShowPaymentModal(false)}>
            See Videos
          </Button>
        }
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error?.subheading}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default Share;
