import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { getPluralPhrase, getCheckInLink } from '../../../Utils';

import SellingPoint from '../../../components/sellingPoint';
import { URL } from '../../../constants';

const AttendeesStep = (props) => {
  const { peopleCount, isReleaseDatePassed } = props;

  const eventId = useParams()?.eventId;

  if (peopleCount === 0)
    return (
      <React.Fragment>
        <SellingPoint
          text={
            <div>
              Share{' '}
              <Link
                className="text-white"
                to={getCheckInLink(eventId)}
                target="_blank"
              >
                this link
              </Link>{' '}
              to allow people to self check-in.
            </div>
          }
        />

        <div>
          Please inform your attendees of the release date you’ve set up, as
          they won’t be able to check in once the analysis has begun.
        </div>

        <div>
          Want to add attendees manually? Upload people{' '}
          <Link to={`/${eventId}${URL.VB_PEOPLE}`}>here</Link>
        </div>
      </React.Fragment>
    );

  return (
    <div>
      <b>{getPluralPhrase(peopleCount, 'person')}</b> checked-in.{' '}
      {!isReleaseDatePassed && (
        <Link to={`/${eventId}${URL.VB_PEOPLE}`}>Check-in more people</Link>
      )}
    </div>
  );
};

export default AttendeesStep;
