import config from '../../config';
import { getAccessTokenClient, getAttendeeClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);
const attendeeClient = () => getAttendeeClient(baseUrl);

export const privateFaceMerge = async (eventId, faceData) => {
  try {
    const response = await accessTokenClient().put('api/face', {
      eventId,
      ...faceData,
    });

    return response.data.face;
  } catch (err) {
    throw new Error('Error creating person');
  }
};

export const attendeeFaceMerge = async (eventId, faceData) => {
  try {
    const response = await attendeeClient().put('api/face', {
      eventId,
      ...faceData,
    });

    return response.data.face;
  } catch (err) {
    throw new Error('Error creating person');
  }
};

export const deleteFace = async (elementId) => {
  try {
    await accessTokenClient().delete(`api/face/${elementId}`);
  } catch (err) {
    throw new Error(`Error deleting face: ${err.message}`);
  }
};

export const getUploadedFaces = async (eventId) => {
  try {
    const response = await accessTokenClient().get(
      `api/faces?eventId=${eventId}`,
    );
    return response.data.faces;
  } catch (err) {
    throw new Error('Error fetching uploaded faces', err);
  }
};

export const getAttendeeFaceByEmail = async (eventId, email) => {
  try {
    const response = await attendeeClient().get(
      `api/event/${eventId}/face?email=${email}`,
    );
    return response.data;
  } catch (err) {
    if (err.response?.status === 404) return null;
    throw new Error('Error fetching check-in person');
  }
};

export const getAttendeeFaceById = async (faceId) => {
  try {
    const response = await attendeeClient().get(`api/faces/${faceId}`);
    return response.data.face;
  } catch (err) {
    if (err.response?.status === 404) return null;
    throw new Error('Error fetching check-in person');
  }
};

export const getFaceResults = async (eventId) => {
  try {
    const response = await accessTokenClient().get(
      `api/face-results?eventId=${eventId}`,
    );
    return response.data;
  } catch (err) {
    if (err.response.data.message === 'There are no video templates') {
      return null;
    } else
      throw new Error(
        `Error getting face results: ${
          err.response.data.message || err.message
        }`,
      );
  }
};
