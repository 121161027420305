import { getBffClient } from '../client/http';

export const findOneEvent = async (eventId) => {
  try {
    const response = await getBffClient().post(
      `/api/mongodb/events/findOne?eventId=${eventId}`,
    );
    return response.data.event;
  } catch (err) {
    throw new Error(`Error finding event: ${err.response?.data?.error}`);
  }
};

export const setAttendeeReviewData = async ({
  eventId,
  isAttendeeReview,
  releaseDate,
  isRemindLater,
}) => {
  try {
    let url = `/api/mongodb/events/set-attendee-review-data?eventId=${eventId}`;
    if (isAttendeeReview) url += `&isAttendeeReview=${isAttendeeReview}`;
    if (releaseDate) url += `&releaseDate=${releaseDate}`;
    if (isRemindLater) url += `&isRemindLater=${isRemindLater}`;

    await getBffClient().post(url);
  } catch (err) {
    throw new Error(
      `Error updating attendee review data: ${err.response?.data?.error}`,
    );
  }
};
