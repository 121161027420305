import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { validateEmail, validatePassword } from '../../Utils';

import { Button } from '../../components';
import EmailField from '../../components/form/emailField';
import PasswordField from '../../components/form/passwordField';
import SubmitBtn from '../../components/submitBtn';

import {
  URL,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  LINK_EXPIRE_IN_MIN,
} from '../../constants';

import OnboardingPage from './onboardingPage';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showExpModal, setShowExpModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isResetting, setIsResetting] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get('id');

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate('/');
    else {
      const emailSends = JSON.parse(localStorage.getItem('emailSends'));
      let foundEmailSend = emailSends.filter((eSend) => eSend.id === id)[0];

      if (!foundEmailSend) navigate('/');
      else if (new Date() > new Date(foundEmailSend.expiryMoment)) {
        setShowExpModal(true);
        setEmail(foundEmailSend.email);
      } else setEmail(foundEmailSend.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e, setValue) => {
    const value = e.target.value;
    setValue(value);
    setErrors({});
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let { passwordErr, confirmPasswordErr } = validatePassword(
      password,
      confirmPassword,
    );

    if (passwordErr || confirmPasswordErr) {
      let errorsTemp = {
        passwordErr: passwordErr,
        confirmPasswordErr: confirmPasswordErr,
      };

      setErrors(errorsTemp);
    } else {
      setIsResetting(true);

      toast.info('API is not connected yet, so nothing happens');

      // do nothing for now, need API to update pw in DB

      // if (success)  {
      //     navigate(URL.LOGIN);
      // } else {
      //     toast.error("Something went wrong");
      // }

      setIsResetting(false);
    }
  };

  const onSubmitResendEmail = (e) => {
    e.preventDefault();

    let emailErr = validateEmail(email);

    if (emailErr) {
      let errorsTemp = { resendEmailErr: emailErr };
      setErrors(errorsTemp);
    } else {
      // TODO: send real email
      navigate(URL.FORGOT_PASSWORD);
    }
  };

  return (
    <OnboardingPage title="Reset Password" heading="Reset your password">
      <Form noValidate onSubmit={onSubmit}>
        <fieldset disabled={isResetting}>
          <Form.Group>
            <PasswordField
              label="New password"
              name="password"
              value={password}
              isInvalid={errors.passwordErr}
              errMsg={errors.passwordErr}
              onChange={(e) => onChange(e, setPassword)}
            />

            <Form.Text className="text-white">
              Password must be {PASSWORD_MIN_LENGTH}-{PASSWORD_MAX_LENGTH}{' '}
              characters long, contain at least 1 lowercase character, 1
              uppercase character, 1 number, and 1 special character.
            </Form.Text>
          </Form.Group>

          <PasswordField
            label="Confirm new password"
            name="confirmPassword"
            value={confirmPassword}
            isInvalid={errors?.confirmPasswordErr}
            errMsg={errors?.confirmPasswordErr}
            onChange={(e) => onChange(e, setConfirmPassword)}
          />

          <SubmitBtn
            title="Reset Password"
            isProcessing={isResetting}
            className="w-full"
          />
        </fieldset>
      </Form>

      <Modal show={showExpModal} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Link has expired</Modal.Title>
        </Modal.Header>

        <Modal.Body className="flex flex-col gap-4">
          <div>
            Reset password link expires after {LINK_EXPIRE_IN_MIN} minutes. But
            don't worry, we can send the link again.
          </div>

          <Form noValidate onSubmit={onSubmitResendEmail}>
            <EmailField
              value={email}
              isInvalid={errors?.resendEmailErr}
              errMsg={errors?.resendEmailErr}
              onChange={(e) => onChange(e, setEmail)}
              required
            />

            <div className="text-sm italic">
              By clicking 'Resend email' you give consent to send email to the
              provided email address.
            </div>

            <Button type="submit" className="w-full">
              Resend email
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </OnboardingPage>
  );
};

export default ResetPassword;
