import React, { useState } from 'react';
import auth0 from 'auth0-js';

import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { validateEmail } from '../../../Utils';

import EmailField from '../../../components/form/emailField';
import SubmitBtn from '../../../components/submitBtn';

import config from '../../../config';
import { useAttendeeStore } from '../../../stores/attendee';

const { featureToggles } = config;

const authenticate = async (webAuth, event, email) => {
  return new Promise((resolve, reject) => {
    webAuth.authorize(
      {
        state: event.eventId,
        connection: 'email',
        nonce: 'nonce',
        login_hint: email,
      },
      (err, authResult) => {
        if (err) {
          console.error(err);
          return reject(err);
        }

        return resolve(authResult);
      },
    );
  });
};

const EmailStage = (props) => {
  const { event } = props;

  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(null);
  const setAttendeeEmail = useAttendeeStore((state) => state.setAttendeeEmail);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setAttendeeEmail(e.target.value);
    setEmailErr(null);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const redirectUri = `${
      featureToggles.auth0 ? window.location.origin : ''
    }/complete-check-in?eventId=${event.eventId}`;

    let emailErr = validateEmail(email);

    if (emailErr) setEmailErr(emailErr);
    else {
      if (featureToggles.auth0) {
        setIsProcessing(true);
        const webAuth = new auth0.WebAuth({
          domain: process.env.REACT_APP_AUTH0_DOMAIN,
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
          responseType: 'token id_token',
          scope: 'openid profile email',
          redirectUri: redirectUri,
        });
        return authenticate(webAuth, event, email);
      } else {
        navigate(redirectUri);
      }
    }
  };

  return (
    <Form className="w-full flex flex-col gap-4" onSubmit={onSubmit} noValidate>
      <div className="text-center font-bold">
        Tell us where to send your personalised video
      </div>

      <EmailField
        value={email}
        isInvalid={emailErr}
        errMsg={emailErr}
        onChange={(e) => onChangeEmail(e)}
        required
      />

      <Form.Check id="check-api-checkbox">
        <Form.Check.Input
          checked={isTermsAccepted}
          onChange={(e) => setIsTermsAccepted(e.target.checked)}
        />
        <Form.Check.Label>
          I accept the CrowdClip®{' '}
          <a
            href="https://my.crowdclip.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </Form.Check.Label>
      </Form.Check>

      <SubmitBtn
        title="Check-in"
        isProcessing={isProcessing}
        isDisabled={email === '' || emailErr || !isTermsAccepted}
        className="w-full"
      />
    </Form>
  );
};

export default EmailStage;
