import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { isEmpty, getDemoSelected } from '../../../../Utils';
import PopoverBtn from '../../../../components/popoverBtn';
import { DEMO_ID } from '../../../../constants';

import { useEventStore } from '../../../../stores/event';

import PeopleSummary from './peopleSummary';

const DemoComponent = (props) => {
  const { setPeople, handleDemoPeople, handleUploadStart } = props;

  const eventId = useParams()?.eventId;

  const currentEvent = useEventStore((state) => state.currentEvent);

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const { isBtnDisabled, popoverBody } = useMemo(() => {
    let isBtnDisabled = false;
    let popoverBody = '';

    if (demoData.isLimited) {
      isBtnDisabled = !isEmpty(getDemoSelected(demoData.people, true));
      popoverBody = 'Select all people to upload';
    } else {
      isBtnDisabled = isEmpty(getDemoSelected(demoData.people));
      popoverBody = 'Select at least 1 person to upload';
    }

    return { isBtnDisabled, popoverBody };
  }, [demoData.isLimited, demoData.people]);

  if (!isDemo) return null;

  const imitateUploading = () => {
    const selectedPeople = getDemoSelected(demoData.people);

    handleUploadStart();

    setPeople([]);

    const interval = setInterval(() => {
      setPeople((prevPeople) => {
        const nextPeople = [...prevPeople];

        if (nextPeople.length < selectedPeople.length)
          nextPeople.push(selectedPeople[prevPeople.length]);
        else {
          demoData.isPeopleUploaded = true;
          localStorage.setItem('demoData', JSON.stringify(demoData));

          clearInterval(interval);
        }
        return nextPeople;
      });
    }, 1000);

    return () => clearInterval(interval);
  };

  return (
    <React.Fragment>
      <PeopleSummary
        event={currentEvent}
        people={demoData.people}
        isDemo
        handleDemoPeople={handleDemoPeople}
      />

      <PopoverBtn
        onClick={imitateUploading}
        btnClass="w-full sm:w-1/2 h-[var(--form-el-height)] self-center bg-primary-900 text-white hover:opacity-80"
        isDisabled={isBtnDisabled}
        popoverHeader="Cannot upload"
        popoverBody={popoverBody}
      >
        Upload
      </PopoverBtn>
    </React.Fragment>
  );
};

export default DemoComponent;
