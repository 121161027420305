import React from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { isProduction } from './Utils';

const root = ReactDOM.createRoot(document.getElementById('root'));

const untrackedPaths = [
  '/enter-email',
  '/email-verification',
  '/create-account',
];
if (isProduction() && !untrackedPaths.includes(window.location.pathname)) {
  LogRocket.init('6f0rux/httpsmycrowdclipco-fe');
  setupLogRocketReact(LogRocket);
}

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>

    <svg width="0" height="0">
      <linearGradient
        id="icon-primary-gradient"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop stopColor="var(--primary-color)" offset="0%" />
        <stop stopColor="var(--gradient-secondary-color)" offset="100%" />
      </linearGradient>
      <linearGradient
        id="icon-purple-gradient"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
      >
        <stop stopColor="var(--purple-color-light)" offset="0%" />
        <stop stopColor="var(--primary-color-light)" offset="100%" />
      </linearGradient>
    </svg>
  </HelmetProvider>,
);
