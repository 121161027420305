import { create } from 'zustand';
import { getUserEvents } from '../services/api/events';
import { findOneEvent } from '../services/api/mongodb';
import { useUserStore } from './user';

export const useEventStore = create((set, get) => {
  return {
    eventId: null,
    userEvents: [],
    currentEvent: null,
    zipOption: null,
    clipSelections: [],
    generationStatus: null,
    setZipOption: (zipOption) => {
      set((state) => ({ ...state, zipOption }));
    },
    setClipSelections: (clipSelections) => {
      set((state) => ({
        ...state,
        clipSelections,
      }));
    },
    setGenerationStatus: (generationStatus) => {
      set((state) => ({
        ...state,
        generationStatus,
      }));
    },
    getClipSelections: (eventId) => {
      return get().clipSelections[eventId];
    },
    setCurrentEvent: (event) => {
      set((state) => ({ ...state, currentEvent: event }));
    },
    setUserEvents: (events) => {
      set((state) => ({ ...state, userEvents: events }));
    },
    setEventId: async (eventId) => {
      const event =
        get().userEvents.find((event) => event.eventId === eventId) ?? null;
      const dbEvent = await findOneEvent(eventId);

      set((state) => ({
        ...state,
        eventId,
        currentEvent: {
          ...event,
          attendeeReviewData: dbEvent?.attendeeReviewData ?? null,
        },
      }));
    },
    setDemoEvent: (eventId) => {
      const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

      set((state) => ({ ...state, eventId, currentEvent: demoData.event }));
    },
    changeEvent: (eventId) => {
      // get user events list
      const userEvents = get().userEvents;

      // set current event
      const event = userEvents.find((event) => event.eventId === eventId);
      set((state) => ({
        ...state,
        currentEvent: event,
        zipOption: null,
        clipSelections: [],
        generationStatus: null,
      }));

      // clear localStorage with previous event data (grouped)
      // general
      localStorage.removeItem('videoModeSaved');
    },
    updateCurrentEvent: async () => {
      const user = useUserStore.getState().user;
      const currentEvent = get().currentEvent;

      // update user events list
      const events = await getUserEvents(user.session.userId);
      const dbEvent = await findOneEvent(currentEvent.eventId);

      // update current event
      const event = events.find(
        (event) => event.eventId === currentEvent.eventId,
      );
      set((state) => ({
        ...state,
        currentEvent: {
          ...event,
          attendeeReviewData: dbEvent?.attendeeReviewData ?? null,
        },
      }));
    },
  };
});
